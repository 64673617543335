<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import {minLength, required} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Vue from "vue";

// import Toolbar from "@/toolbar";

/**
 * Email-read component
 */
export default {
  components: {
    Layout,
    PageHeader,
    // Toolbar,
    ckeditor: CKEditor.component
  },
  data() {
    return {
      title: "TERM AND CONDITION",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "TERM AND CONDITION",
          active: true
        }
      ],
      showModal: false,
      editor: ClassicEditor,
      term:{
        termData: "",
      },
      submitted: false,


    };

  },
  mounted() {
    Master.pageDetails({
    "page":"terms"
    }).then((res) => {
      console.log(res)
      this.term.termData = res.data.data.content;
    })
  },
  validations: {
    term: {
      termData: {required, maxLength: minLength(100)},
    }
  },
  methods: {
    async handleSubmit() {
      var userData = JSON.parse(localStorage.getItem("user"))

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        Master.updatePageDetails(
            {
              userId:userData.id,
              page : "terms",
              content : this.term.termData
            }).then((res) => {
          console.log(res)
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
        }).catch((err) => {
          console.log(err)
        })

      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <!-- End Left sidebar -->

        <!-- Right Sidebar -->
        <div class="mb-3">
          <div class="card">
            <form  @submit.prevent="handleSubmit">
              <div class="form-group">
                <ckeditor v-model="term.termData" :editor="editor" :class="{ 'is-invalid': submitted && $v.term.termData.$error }"></ckeditor>
                <div v-if="submitted && $v.term.termData.$error" class="invalid-feedback">
                  <span v-if="!$v.term.termData.required">TERM AND CONDITION IS REQUIRED</span>
                  <span v-if="!$v.term.termData.minLength">TERM AND CONDITION SHOULD BE AT LEAST 100 CHARACTER</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" align="center">
                  <div class=" mb-4">
                    <button
                        type="submit"
                        class="btn btn-primary mr-2 waves-effect waves-light"
                    >SAVE</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->
    </div>

  </Layout>
</template>
